<template>
  <page-container>
    <song-player v-if="Object.keys(currentSong).length" :song="currentSong" :showClose="false"></song-player>
    <div v-else>
      <div v-if="state === 'loading'" class="text-center mt-10">
        Fetching the song...
      </div>
      <div v-else class="text-center mt-10">
        <div>Sorry, the song is not found.</div>
        <v-btn dark large rounded style="text-transform: none;" color="secondary darken-1" class="mt-10">
          Explore All Songs
        </v-btn>
      </div>
    </div>
  </page-container>
</template>

<script>
import firebase from 'firebase'

export default {
  props: ['song_id'],
  data: () => ({
    currentSong: {},
    state: 'loading'
  }),
  created () {
    this.showSpinner()
    firebase.firestore()
      .collection('songs')
      .where('song_id', '==', this.song_id)
      .onSnapshot(
        snapshot => {
          try {
            if (snapshot.docs.length) {
              const song = snapshot.docs[0].data()
              this.currentSong = {
                song_id: song.song_id,
                title: song.title,
                title_bn: song.title_bn,
                singer: song.singer,
                production: song.production,
                composer: song.composer,
                lyricist: song.lyricist,
                album: song.album,
                release_year: song.release_year,
                yt_id: song.yt_id
              }
            } else {
              this.state = 'error'
            }
          } finally {
            this.hideSpinner()
          }
        },
        error => {
          console.error('An unexpected Firestore error occurred:', error)
          this.state = 'error'
          this.hideSpinner()
        }
      )
  },
  components: {
    SongPlayer: () => import('./SongPlayer'),
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>
